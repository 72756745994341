<template>
  
</template>

<script>
import { toqqLogin } from '@/api/login'
import { setLocal } from '@/lib/local'
import { bindQq } from '@/api/userInfo'

export default {
  mounted () {
    if(window.location.href.split(/[?&]/)[1] == 'bind') {
      this.toBind()
    } else {
      this.toLogin()
    }
  },
  methods: {
    async toLogin () {
      const res = await toqqLogin({code: window.location.href.split('=')[1]})
      if(res.code == 0) {
        this.$message.success('登录成功')
        setLocal(res.data.token)
        // setLocal(JSON.stringify(res.data.roleIds), 'roleIds')
        this.$router.push({name: 'Index', params: {tipsBind: true}})
      }else if (res.cdoe == 6004) {
        this.$message('你已被禁用,请联系客服人员!!!')
        this.$router.push('/')
      }
    },
    async toBind () {
      const res = await bindQq({code: window.location.href.split('=')[1]})
      if(res.code == 0) {
        this.$message.success('绑定成功')
        this.$router.push('/PersonalCenter/AccountInfo')
      } else {
        this.$message.error('该QQ已绑定其他账号')
        setTimeout(() => {
          this.$router.push('/PersonalCenter/AccountInfo')
        }, 2000);
      }
    }
  }
}
</script>

<style>

</style>